import Image from "components/Image"
import tw, { css, styled } from "twin.macro"

import { H1, H6, IntroBody } from "styles/Text"

import MetaData from "components/Metadata"
import Container from "components/Container"
import TransparentOverlay from "components/Heroes/TransparentOverlay"
import ScrollButton from "components/Buttons/ScrollButton"
import { gridBreakpoints } from "styles/Variables"
import Language from "components/Language"

export interface HeroGenericProps {
  id: string
  BackgroundImage: {
    alternativeText: string
    url: string
  }
  ImageFocusHorizontal?: "left" | "center" | "right"
  ImageFocusVertical?: "top" | "center" | "bottom"
  Title: string
  UpperText?: string
  Description: string
}

export const HeroGenericQuery = {
  component: "ComponentHeroGeneric",
  fields: `{
    id
    BackgroundImage {
      alternativeText
      url
    }
    ImageFocusHorizontal
    ImageFocusVertical
    Title
    UpperText
    Description
  }`,
}

const HeroGenericStyled = styled.header(() => [
  tw`
    relative
    flex flex-col justify-center items-center
    bg-black text-white text-center
    print:text-black
    print:text-left
  `,
  css`
    @media screen {
      min-height: 720px;
    }
    @media screen and (min-width: ${gridBreakpoints.lg}px) {
      min-height: 860px;
    }
  `,
])

const HeroGeneric = (props: HeroGenericProps) => {
  const {
    Title,
    Description,
    BackgroundImage,
    UpperText,
    ImageFocusHorizontal = "center",
    ImageFocusVertical = "center",
  } = props
  return (
    <HeroGenericStyled>
      <MetaData Title={Title} Description={Description} />
      {BackgroundImage && (
        <Image
          aria-hidden="true"
          alt={BackgroundImage.alternativeText}
          src={BackgroundImage.url}
          layout="fill"
          objectFit="cover"
          objectPosition={[ImageFocusHorizontal, ImageFocusVertical].join(" ")}
          priority
        />
      )}

      <Container tw="z-10">
        {UpperText && (
          <H6 tw="mb-2">
            <Language unwrap>{UpperText}</Language>
          </H6>
        )}
        <H1 tw="print:(absolute top-0 left-0)">
          <Language unwrap>{Title}</Language>
        </H1>
        <IntroBody tw="mt-5 mx-auto max-w-xs md:max-w-2xl">
          <Language unwrap>{Description}</Language>
        </IntroBody>
      </Container>

      <ScrollButton href="#dynamicContent" />
      <TransparentOverlay />
    </HeroGenericStyled>
  )
}

export default HeroGeneric
