const ArrowDown = (props) => (
  <svg
    width="50"
    height="50"
    viewBox="0 0 50 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx="25" cy="25" r="24.5" stroke="white" />
    <path d="M25 20.625L25 29.375" stroke="white" />
    <path d="M29.375 25L25 29.375L20.625 25" stroke="white" />
  </svg>
)

export default ArrowDown
