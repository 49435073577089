import tw, { styled, css } from "twin.macro"

export const TransparentOverlay = styled.div(() => [
  tw`absolute top-0 left-0 w-full h-full bg-no-repeat`,
  css`
    background-image: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.2) 0%,
      rgba(0, 0, 0, 0.2) 100%
    );
  `,
])

export default TransparentOverlay
