import tw from "twin.macro"
import ArrowDown from "icons/ArrowDown"
import SRText from "components/SRText"

const ScrollButton = (props, { href }) => (
  <a href={href} tw="z-10 absolute bottom-10 print:hidden" {...props}>
    <ArrowDown tw="w-12.5 h-12.5 lg:(w-20 h-20)" />
    <SRText>Scroll to content</SRText>
  </a>
)

export default ScrollButton
